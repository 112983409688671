import {createContext, useContext, useEffect, useState} from "react";
import axios from "axios";
import {useMutation} from "react-query";
import {useAuth} from "@/hooks/AuthContext";

const initialValues = {
    currentSeason: {},
    currentScore: {},
    tasks: [],
    mutateScore: ()=>{},
    isLoadingScore: bool => false,
    refetchScore: () => {},
    refetchAirdrop: () => {},
    setAccessToken: (token) => {},
};

const AirdropContext = createContext(initialValues)

const useAirdrop = () => {
    return useContext(AirdropContext)
}

const AirdropProvider = ({children}) => {
    const [currentSeason, setCurrentSeason] = useState({})
    const [tasks, setTasks] = useState([])
    const [currentScore, setScore] = useState({})
    const {auth} = useAuth()
    const [accessToken, setAccessToken] = useState(null);
    const {mutate} = useMutation({
        mutationKey: "airdrop-season",
        mutationFn: () => axios.get("/api/game-taptap/seasons?filter[status]=Ongoing",{
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }),
        onSuccess: (data) => {
            setCurrentSeason(data.data.data[0])
        },
        onError: (error) => console.error("Error fetching airdrop season: ", error),
    })

    const {mutate: mutateScore, isLoading: isLoadingScore} = useMutation({
        mutationKey: 'airdrop-score',
        mutationFn: () => axios.get('/api/game-taptap/score',{
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }),
        onSuccess: data => {
            if(data.data.data?.length > 0) {
                setScore(data.data.data[0])
            }

        }
    })

    const refetchAirdrop = () => {
       mutate();
    }

    const refetchScore = () => {
        mutateScore()
    }

    useEffect(() => {

    }, [])

    useEffect(() => {
        if(currentSeason?.uuid) {
            // mutateTask({season_id: currentSeason.id})
        }
    }, [currentSeason]);

    useEffect(() =>{
        if(!accessToken) return ;
        // alert(accessToken)
        refetchAirdrop()
        refetchScore()
    },[accessToken])

    return (
        <AirdropContext.Provider value={{
            currentSeason: currentSeason,
            tasks: tasks,
            currentScore: currentScore,
            mutateScore,
            isLoadingScore,
            refetchScore,
            refetchAirdrop,
            setAccessToken
        }}>
            {children}
        </AirdropContext.Provider>
    )
}

export {AirdropProvider, useAirdrop}