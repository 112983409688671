"use client";

import {
  createWeb3Modal as createWeb3ModalEVM,
  defaultConfig,
} from "@web3modal/ethers/react";

const projectId = "bd330e0387216caf3a534c90a2e623d1";

const ganache = {
  chainId: 1337,
  name: "Ganache",
  currency: "ETH",
  explorerUrl: "https://etherscan.io",
  rpcUrl: "http://127.0.0.1:7545",
};

const bullniumDev = {
  chainId: 1337,
  name: "Bullnium Dev",
  currency: "ETH",
  explorerUrl: "https://etherscan.io",
  rpcUrl: "http://192.168.25.51:8545",
  // rpcUrl: "http://127.0.0.1:7545",
};

const mainnet = {
  chainId: 1,
  name: "Ethereum",
  currency: "ETH",
  explorerUrl: "https://etherscan.io",
  rpcUrl: "https://cloudflare-eth.com",
};

const binanceSmartChain = {
  chainId: 56,
  name: "Binance Smart Chain",
  currency: "BNB",
  explorerUrl: "https://bscscan.com",
  rpcUrl: "https://bsc-dataseed.binance.org",
};

const binanceSmartChainTestnet = {
  chainId: 97,
  name: "BSC Testnet",
  currency: "BNB",
  explorerUrl: "https://testnet.bscscan.com",
  rpcUrl: "https://bsc-testnet-rpc.publicnode.com",
};

const polygon = {
  chainId: 137,
  name: "Polygon",
  currency: "MATIC",
  explorerUrl: "https://polygonscan.com",
  rpcUrl: "https://rpc-mainnet.maticvigil.com",
};

const arbitrum = {
  chainId: 42161,
  name: "Arbitrum",
  currency: "ETH",
  explorerUrl: "https://arbiscan.io",
  rpcUrl: "https://arb1.arbitrum.io/rpc",
};

const fantom = {
  chainId: 250,
  name: "Fantom",
  currency: "FTM",
  explorerUrl: "https://ftmscan.com",
  rpcUrl: "https://rpcapi.fantom.network",
};

const metadata = {
  name: "AuditDeFi",
  description: "AuditDeFi Audit Platform",
  url: "https://auditdefi.com",
  icons: ["https://auditdefi.com/android-icon-192x192.webp"],
};

const ethersConfig = defaultConfig({
  metadata,
  enableEIP6963: true,
  enableInjected: true,
  enableCoinbase: true,
  rpcUrl: "...",
  defaultChainId: 1337, // Ubah defaultChainId ke 1337 untuk mendukung bullniumDev
});

let devChain = [];
if(process.env.NEXT_PUBLIC_ENVIRONMENT === "local" || process.env.NEXT_PUBLIC_ENVIRONMENT === "Testnet"){
  devChain = [ganache, bullniumDev, binanceSmartChainTestnet];
}

createWeb3ModalEVM({
  ethersConfig,
  chains: [
      ...devChain,
    mainnet,
    binanceSmartChain,
    polygon,
    arbitrum,
    fantom,
  ], // Tambahkan ganache untuk mendukung custom RPC
  projectId,
  enableAnalytics: true,
  enableOnramp: true,
  themeVariables: {
    "--w3m-accent": "#3D88FC",
    "--w3m-border-radius-master": "0.038rem",
  },
});

export function Web3EVMModal({ children }) {
  return children;
}
