import { GoogleAnalytics } from "@next/third-parties/google";
import Web3Provider from "@/hooks/Web3Provider";
import "../styles/globals.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Head from "next/head";
import { useRouter } from "next/router";
import { Web3EVMModal } from "@/components/Web3EVMModal";
import { AuthInit, AuthProvider } from "@/hooks/AuthContext";
// import { Web3SolanaModal } from "@/components/Web3SolanaModal";
import { QueryClient, QueryClientProvider } from "react-query";
import { AirdropProvider } from "@/hooks/AirdropContext";

export default function App({ Component, pageProps }) {
  const router = useRouter();
  const p = router.asPath.slice(1);
  const canonicalURL = `https://auditdefi.com/${p}`.split("?")[0];

  const queryClient = new QueryClient();
  return (
    // <Web3Provider>
    //   <Head>
    //     <link rel="canonical" href={canonicalURL} />
    //   </Head>
    //   <Component {...pageProps} />
    //   <GoogleAnalytics
    //     gaId="G-EC0W17C16N"
    //   >
    //   </GoogleAnalytics>
    //   <ToastContainer />
    // </Web3Provider>
    <Web3EVMModal>
      {/* <Web3SolanaModal> */}
      <Web3Provider>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <AuthInit>
              <AirdropProvider>
                <Head>
                  <link rel="canonical" href={canonicalURL} />
                </Head>
                <Component {...pageProps} />
                <GoogleAnalytics gaId="G-EC0W17C16N"></GoogleAnalytics>
                <ToastContainer />
              </AirdropProvider>
            </AuthInit>
          </AuthProvider>
        </QueryClientProvider>
      </Web3Provider>
      {/* </Web3SolanaModal> */}
    </Web3EVMModal>
  );
}
